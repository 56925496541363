import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./index.css";

function NotFoundPageComponent() {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.project && params.project === "index.html") {
      navigate("/crowningverse");
    }
  }, []);
  return (
    <div className="not-found-page">
      <img src="/assets/images/ol_logo.png" alt="logo" className="logo" />
      <h2 className="sub-heading">ooo</h2>
      <h1>Not found</h1>
    </div>
  );
}

export default NotFoundPageComponent;
