/* eslint-disable no-unused-expressions */
import { React, useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../Store/AppContext";
// detect device
import {
  isMobile,
  isMobileOnly,
  withOrientationChange,
} from "react-device-detect";
// projects
import { systemsProjects, oooProjects } from "./projects";
// components
import NotFoundPageComponent from "../NotFoundPageComponent";
import ProjectComponent from "../ProjectComponent";
import CrowningArtsComponent from "../CrowningArtsComponent";
import ErbiaComponent from "../ErbiaComponent";
import CaseStudyComponent from "../CaseStudyComponent";

function PortfolioPageComponent(props) {
  const params = useParams();
  const navigate = useNavigate();
  // data
  const app = useContext(AppContext);
  const { imagesPath } = app.state;
  const main = useRef(null);

  // local state
  const portfolio = "portfolio-ooo";
  const [projects] = useState(oooProjects);
  const [openedProject, setOpenedProject] = useState(projects[0]);

  useEffect(() => {
    if (!params.project) {
      setOpenedProject(projects[0]);
      navigate(projects[0].key);
    }
  }, [navigate, params.project, projects]);

  useEffect(() => {
    setOpenedProject(
      projects.find((item) => item.key === params.project)
        ? projects.find((item) => item.key === params.project)
        : {}
    );
  }, [params, projects]);

  useEffect(() => {
    if (props.isLandscape && window.innerWidth <= 992) {
      main.current.classList.add("landscape-view");
    } else {
      main.current.classList.remove("landscape-view");
    }
  }, [props.isLandscape]);

  /**
   * Render project
   */
  const renderProject = (project) => {
    let component = <NotFoundPageComponent />;
    if (
      //projects &&
      //projects.length > 0 &&
      projects.find((item) => item.key === project)
    ) {
      switch (project) {
        case "smarttrust":
          component = (
            <ProjectComponent
              project={projects.find((item) => item.key === project)}
              projects={projects}
              onHandleNav={(action) => handleNav(action)}
            />
          );

          break;
        case "kim":
          component = (
            <ProjectComponent
              project={projects.find((item) => item.key === project)}
              projects={projects}
              onHandleNav={(action) => handleNav(action)}
            />
          );

          break;
        case "cryptoswap":
          component = (
            <ProjectComponent
              project={projects.find((item) => item.key === project)}
              projects={projects}
              onHandleNav={(action) => handleNav(action)}
            />
          );
          break;
        case "crowningverse":
          component = (
            <CrowningArtsComponent
              project={projects.find((item) => item.key === project)}
              projects={projects}
            />
          );

          break;
        case "erbia":
          component = (
            <ErbiaComponent
              project={projects.find((item) => item.key === project)}
              projects={projects}
            />
          );

          break;
        case "soukup-partners":
          component = (
            <CaseStudyComponent
              project={projects.find((item) => item.key === project)}
              projects={projects}
            />
          );

          break;
        default:
          component = <NotFoundPageComponent />;
          break;
      }
    } /*else {
      if (project) {
        return <NotFoundPageComponent />;
      }
    } */

    return component;
  };

  /**
   * Handle navigation
   *
   * @param {string} direction
   */
  const handleNav = (direction) => {
    switch (direction) {
      case "prev":
        if (openedProject.id !== 1) {
          navigate(
            `/${projects.find((item) => item.id === openedProject.id - 1).key}`
          );
          window.scrollTo({ top: 0, behavior: "instant" });
        }
        break;
      case "next":
        if (openedProject.id !== Math.max(...projects.map((item) => item.id))) {
          navigate(
            `/${projects.find((item) => item.id === openedProject.id + 1).key}`
          );
          window.scrollTo({ top: 0, behavior: "instant" });
        }
        break;
      default:
        //
        break;
    }
  };

  return (
    <div
      ref={main}
      className={`portfolio ${portfolio}${
        openedProject && openedProject.key
          ? ` ${portfolio}-${openedProject.key}`
          : ""
      }${
        props.isLandscape && window.innerWidth < 992 ? " landscape-view" : ""
      }`}
    >
      {/* top nav */}

      <span
        className="portfolio-top-nav-btn portfolio-top-nav-btn-left"
        onClick={() => handleNav("prev")}
        style={openedProject.id === 1 ? { display: "none" } : {}}
      >
        {/* <img src={`${imagesPath}/left-arrow.svg`} alt="icon" /> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <g clipPath="url(#clip0_1054_4)">
            <path
              d="M7.69863 14.9711L22.2441 0.426024C22.812 -0.142024 23.7331 -0.142024 24.3011 0.426121C24.8691 0.99417 24.8691 1.91509 24.301 2.48314L10.7841 15.9997L24.3011 29.517C24.8691 30.0851 24.8691 31.006 24.301 31.574C24.0171 31.8581 23.6448 32 23.2725 32C22.9003 32 22.528 31.8581 22.244 31.574L7.69863 17.0281C7.42575 16.7553 7.27254 16.3854 7.27254 15.9997C7.27254 15.6139 7.42575 15.2439 7.69863 14.9711Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_1054_4">
              <rect
                width="32"
                height="32"
                fill="white"
                transform="matrix(-1 0 0 1 32 0)"
              />
            </clipPath>
          </defs>
        </svg>
      </span>

      <span
        className="portfolio-top-nav-btn portfolio-top-nav-btn-right"
        onClick={() => handleNav("next")}
        style={
          openedProject.id === Math.max(...projects.map((item) => item.id))
            ? { display: "none" }
            : {}
        }
      >
        {/*<img src={`${imagesPath}/right-arrow.svg`} alt="icon" /> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <g clipPath="url(#clip0_1054_2)">
            <path
              d="M24.3014 14.9711L9.75592 0.426024C9.18797 -0.142024 8.26695 -0.142024 7.6989 0.426121C7.13095 0.99417 7.13095 1.91509 7.699 2.48314L21.2159 15.9997L7.6989 29.517C7.13095 30.0851 7.13095 31.006 7.699 31.574C7.98292 31.8581 8.35519 32 8.72746 32C9.09973 32 9.47199 31.8581 9.75602 31.574L24.3014 17.0281C24.5742 16.7553 24.7275 16.3854 24.7275 15.9997C24.7275 15.6139 24.5742 15.2439 24.3014 14.9711Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_1054_2">
              <rect width="32" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </span>

      {renderProject(params.project)}
      {/* <div className="portfolio-nav">
        <a
          className="portfolio-nav-item"
          onClick={() => handleNav("prev")}
          style={
            openedProject.id === 1
              ? { cursor: "not-allowed", background: "#eee" }
              : {}
          }
        >
          <span>prev</span>
        </a>
        <a
          className="portfolio-nav-item"
          onClick={() => handleNav("next")}
          style={
            openedProject.id === Math.max(...projects.map((item) => item.id))
              ? { cursor: "not-allowed", background: "#eee" }
              : {}
          }
        >
          <span>next</span>
        </a>
      </div> */}
    </div>
  );
}

export default withOrientationChange(PortfolioPageComponent);
