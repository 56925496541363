const imagesPath = "/assets/images/";
export const systemsProjects = [
  {
    id: 1,
    key: "smarttrust",
    name: "Smart Trust",
    sections: [
      {
        key: "intro",
        title: "Smart Trust",
        subTitle: "Trust notarization using smart contracts in blockchain",
      },
      {
        key: "description",
        title: "Project description",
        subTitle: `Smart Trust is a blockchain based application for creating and managing trusts using smart contract on the Ethereum network`,
        list: [
          "corporate identity",
          "design and concept",
          "prototype",
          "POC (2versions)",
          "MVP",
          "front end",
          "back end",
          "blockchain functionality integration",
        ],
      },
      {
        key: "visuals",
        title: "Visuals & concept",
        galleryItemsPerPage: 4,
        gallery: [
          {
            id: 1,
            name: "Application dashboard",
            thumbUrl: `${imagesPath}smarttrust/gallery/t_st_01.png`,
            url: `${imagesPath}smarttrust/gallery/ss_st_01.png`,
          },
          {
            id: 2,
            name: "Trust detail - onboarded assets",
            thumbUrl: `${imagesPath}smarttrust/gallery/t_st_02.png`,
            url: `${imagesPath}smarttrust/gallery/ss_st_02.png`,
          },
          {
            id: 3,
            name: "Trust detail - rules and conditions",
            thumbUrl: `${imagesPath}smarttrust/gallery/t_st_03.png`,
            url: `${imagesPath}smarttrust/gallery/ss_st_03.png`,
          },
          {
            id: 4,
            name: "Trust physical notarization",
            thumbUrl: `${imagesPath}smarttrust/gallery/t_st_04.png`,
            url: `${imagesPath}smarttrust/gallery/ss_st_04.png`,
          },
          {
            id: 5,
            name: "Trust blockchain notarization",
            thumbUrl: `${imagesPath}smarttrust/gallery/t_st_05.png`,
            url: `${imagesPath}smarttrust/gallery/ss_st_05.png`,
          },
          {
            id: 6,
            name: "Token distribution modal",
            thumbUrl: `${imagesPath}smarttrust/gallery/t_st_06.png`,
            url: `${imagesPath}smarttrust/gallery/ss_st_06.png`,
          },
          {
            id: 7,
            name: "Smart Trust on mobile",
            thumbUrl: `${imagesPath}smarttrust/gallery/t_st_07.png`,
            url: `${imagesPath}smarttrust/gallery/ss_st_07.png`,
          },
        ],
      },
      {
        key: "coding",
        title: "Coding",
        list: [
          "Node.js",
          "Express.js",
          "Swagger.js",
          "Mongo Database Management",
          "Redis",
          "React.js",
          "Redux.js",
          "Ant design",
          "Less",
        ],
      },
      {
        key: "blockchain",
        title: "Blockchain functionality",
        subTitle:
          "Adding digital technology as well as blockchain functionality to the current traditional way of creating and managing Trusts insures safe and effortless way to create and manage Trust deeds and Letter of wishes, with and extra layer of security features such as smart contract notarizations in blockchain, multi signature, tokenization and much more.",
        blockchainTechList: {
          title: "Blockchain technology used:",
          items: ["Ethereum", "Solidity", "Infura", "Web3.js", "Ethers.js"],
        },
        smartContractFuncList: {
          title: "Smart contract functionality",
          items: [
            {
              title: "Role management",
              text: "Allows onboarding of users by using their Ethereum public keys and assigning them as various roles which determines their rights in Trust management process (trustee, settlor, protector).",
            },
            {
              title: "Notarizations",
              text: "Blockchain stored records which serve as transparent evidence of an action executed in relation to the Trust.",
            },
            {
              title: "Multi-signatures",
              text: "Function which requires multiple blockchain signatures in order to execute an action (for example in order to create a Trust deed, all the parties relative to the trust must agree by the blockchain signature).",
            },
            {
              title: "Rules and conditions",
              text: "Once the  rules and conditions for the Trust asset distribution are met, an oracle call to blockchain ismade which initiates the asset distribution amongst the beneficiaries. Each condition can have multiple sub condition levels.",
            },
            {
              title: "Tokenization",
              text: "(+token distribution to beneficiaries wallets) - a concept of asset onboarding, which is represented by a desired amount of tokens which can be automatically distributed by a final destination (public keys) when certain condition is met.",
            },
            {
              title: "Events",
              text: "Event are emitted whenever a certain action related to the trust is executed and creates a public and transparent record on the blockchain.",
            },
            {
              title: "Blockchain report",
              text: "Consists of data stored on the blockchain by previously mentioned events, as well as offchain data stored within the Smart Trust system. Serves as a  “human readable report” of all the actions related to the trust since its very creation.",
            },
            {
              title: "KYC/AML",
              text: "Blockchain stored evidence of a successful customer KYC verification process provided by a 3rd. party company.",
            },
            {
              title: "Multi - smart contract structures",
              text: "Smart Trust uses a structure of multiple smart contracts in order to provide the highest security possible while maintaining as low transaction costs as possible.",
            },
          ],
        },
      },
      {
        key: "corporate",
        title: "Corporate identity & design",
        list: [
          "Logo creation",
          "Application design",
          "Website creation",
          "White paper and marketing materials",
          "Design manual",
          "Prototyping",
        ],
      },
      {
        key: "media",
        title: "Media",
        list: [
          {
            title: "",
            text: "A short guide on creating and managing trusts using smart contracts in blockchain",
            thumbUrl: `${imagesPath}smarttrust/media_thumb_st01.png`,
            videoUrl: "https://youtu.be/3d5r_jq8DuM",
            videoDuration: "5:34",
          },
          {
            title: "",
            text: "Smart trust advertisement",
            thumbUrl: `${imagesPath}smarttrust/media_thumb_st02.png`,
            videoUrl: "https://youtu.be/9ZujReUMk5g",
            videoDuration: "1:21",
          },
          {
            title: "",
            text: "A guide on creating and managing trusts using smart contracts in blockchain",
            thumbUrl: `${imagesPath}smarttrust/media_thumb_st01.png`,
            videoUrl: "https://youtu.be/mchvnrq8U8o",
            videoDuration: "10:01",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    key: "kim",
    name: "Kim",
    sections: [
      {
        key: "intro",
        title: "K.im",
        subTitle: "The Ultimate Content Monetisation System",
      },
      {
        key: "description",
        title: "Project description",
        subTitle: `Providing a comprehensive suite of advanced services and technologies to manage, protect and sell every digital content, K.im aims to bring one of the biggest revolutions in the digital content distribution and monetisation industry. Create your content, tokenize and secure it using K.im, sell it online using crypto!`,
        list: [
          "dummy <span>(non-blockchain)</span> PoC",
          "prototype",
          "fully functional MVP <span>(blockchain functionality integrated)</span>",
          "front end",
          "back end",
        ],
        image: {
          url: `${imagesPath}kim/desc_img.png`,
        },
      },
      {
        key: "visuals",
        title: "Visuals & concept",
        galleryItemsPerPage: 4,
        gallery: [
          {
            id: 1,
            name: "K.im",
            thumbUrl: `${imagesPath}kim/gallery/t_kim_01.png`,
            url: `${imagesPath}kim/gallery/ss_kim_01.png`,
          },
          {
            id: 2,
            name: "Encryption and upload process",
            thumbUrl: `${imagesPath}kim/gallery/t_kim_02.png`,
            url: `${imagesPath}kim/gallery/ss_kim_02.png`,
          },
          {
            id: 3,
            name: "Encryption and upload process",
            thumbUrl: `${imagesPath}kim/gallery/t_kim_03.png`,
            url: `${imagesPath}kim/gallery/ss_kim_03.png`,
          },
          {
            id: 4,
            name: "Your digital content",
            thumbUrl: `${imagesPath}kim/gallery/t_kim_04.png`,
            url: `${imagesPath}kim/gallery/ss_kim_04.png`,
          },
        ],
      },
      {
        key: "blockchain",
        title: "Blockchain functionality",
        subTitle:
          "The use of blockchain technology in this project enables K.im platform to secure the content you create by adding another layer of security to the files and notarizing it in blockchain. No keys are being held by K.im platform. You control your content, we provide the technology!",
        blockchainTechList: {
          title: "Blockchain technology used:",
          items: ["Ethereum", "Solidity", "Infura", "Web3.js", "Ethers.js"],
        },
      },
      {
        key: "corporate",
        title: "Corporate identity & design",
        list: [
          "Application design",
          "Website cooperation",
          "White paper and marketing materials",
          "Prototyping",
        ],
      },
      {
        key: "media",
        title: "Media",
        list: [
          {
            title: "",
            text: "K.im product video",
            thumbUrl: `${imagesPath}kim/media_thumb_kim.png`,
            videoUrl: "https://youtu.be/T-ZJAQk3NmY",
            videoDuration: "4:54",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    key: "cryptoswap",
    name: "Crypto Swap",
    sections: [
      {
        key: "intro",
        title: "Crypto Swap",
        subTitle: "Anonymous crypto exchange application",
      },
      {
        key: "description",
        title: "Project description",
        subTitle: `Our little anonymous crypto exchange - made just "for fun" and to see how fast we can build it.<br>
          Took us a few days, 2 liters of coffee and 12 API's … :) I know, right?<br>
          Enter the "from" wallet address, enter the "to" wallet address ...ploop - done!`,
        list: [
          "prototype",
          "fully functional APP <span>(blockchain functionality integrated)</span>",
          "front end",
          "back end",
          "API creation and integration",
        ],
      },
      {
        key: "visuals",
        title: "Visuals & concept",
        galleryItemsPerPage: 4,
        gallery: [
          {
            id: 1,
            name: "Crypto Swap",
            thumbUrl: `${imagesPath}cryptoswap/gallery/t_crypto.png`,
            url: `${imagesPath}cryptoswap/gallery/ss_crypto.png`,
          },
        ],
      },
      {
        key: "blockchain",
        title: "Blockchain functionality",
        blockchainTechList: {
          items: ["Ethereum", "Solidity", "Infura", "Web3.js", "Ethers.js"],
        },
      },
      {
        key: "corporate",
        title: "Corporate identity & design",
        list: ["Prototyping", "Application design / GUI"],
      },
      {
        key: "media",
        title: "Media",
        list: [
          {
            title: "",
            text: "CryptoChange (ex. CryptoSwap) product video",
            thumbUrl: `${imagesPath}cryptoswap/media_thumb_crypto.png`,
            videoUrl: "https://youtu.be/Lg9V68iJnEE",
            videoDuration: "3:38",
          },
        ],
      },
    ],
  },
];

export const oooProjects = [
  { id: 1, key: "crowningverse", name: "Crowning Verse", sections: [] },
  { id: 2, key: "erbia", name: "Erbia", sections: [] },
  {
    id: 3,
    key: "soukup-partners",
    name: "Soukup Partners",
    sections: [
      {
        id: 1,
        key: "intro",
        imgBck: {
          light: `${imagesPath}soukup-partners/intro-img-bck-light.png`,
          dark: `${imagesPath}soukup-partners/intro-img-bck-dark.png`,
        },
        img: `${imagesPath}soukup-partners/intro-img.png`,
      },
      {
        id: 2,
        key: "about",
        description:
          "A website for a prestige law firm located in Prague Czech Republic.",
        overviewText:
          "A remake of an old existing website including a bit of re-branding, setting new style including new fonts, colours in a new modern look. With several custom breakpoints, this brand new look and feel of the website perfectly represents the style of our very good client",
        overviewAuthor: "- JUDr. Jan Soukup",
        overviewImg: {
          light: `${imagesPath}soukup-partners/about-img-light.svg`,
          dark: `${imagesPath}soukup-partners/about-img-dark.svg`,
        },
      },
      {
        id: 3,
        key: "design",
        colors: [
          {
            icon: `${imagesPath}soukup-partners/FFFFFF.svg`,
            iconDark: `${imagesPath}soukup-partners/FFFFFF.svg`,
            text: "FFFFFF",
          },

          {
            icon: `${imagesPath}soukup-partners/D7D7D7.svg`,
            iconDark: `${imagesPath}soukup-partners/D7D7D7.svg`,
            text: "D7D7D7",
          },
          {
            icon: `${imagesPath}soukup-partners/D1B06B.svg`,
            iconDark: `${imagesPath}soukup-partners/D1B06B.svg`,
            text: "D1B06B",
          },
          {
            icon: `${imagesPath}soukup-partners/2E3947.svg`,
            iconDark: `${imagesPath}soukup-partners/2E3947.svg`,
            text: "2E3947",
          },
          {
            icon: `${imagesPath}soukup-partners/1F2732.svg`,
            iconDark: `${imagesPath}soukup-partners/1F2732.svg`,
            text: "1F2732",
          },
          {
            icon: `${imagesPath}soukup-partners/161D27.svg`,
            iconDark: `${imagesPath}soukup-partners/161D27-dark.svg`,
            text: "161D27",
          },
        ],
      },
      {
        id: 4,
        key: "typography",
        families: [
          {
            key: "cormorant",
            title: "Cormorant",
            types: [
              {
                key: "light",
                title: "Light",
                rows: ["ABCDEFGHIJK", "LMNOPQRSTU", "VWXYX"],
              },
              {
                key: "regular",
                title: "Regular",
                rows: ["ABCDEFGHIJK", "LMNOPQRSTU", "VWXYX"],
              },
              {
                key: "medium",
                title: "Medium",
                rows: ["ABCDEFGHIJK", "LMNOPQRSTU", "VWXYX"],
              },
              {
                key: "semi-bold",
                title: "Semi bold",
                rows: ["ABCDEFGHIJK", "LMNOPQRSTU", "VWXYX"],
              },
              {
                key: "bold",
                title: "Bold",
                rows: ["ABCDEFGHIJK", "LMNOPQRSTU", "VWXYX"],
              },
            ],
          },
          {
            key: "oxygen",
            title: "Oxygen",
            types: [
              {
                key: "light",
                title: "Light",
                rows: ["ABCDEFGHIJK", "LMNOPQRSTU", "VWXYX"],
              },
              {
                key: "regular",
                title: "Regular",
                rows: ["ABCDEFGHIJK", "LMNOPQRSTU", "VWXYX"],
              },
              {
                key: "medium",
                title: "Medium",
                rows: ["ABCDEFGHIJK", "LMNOPQRSTU", "VWXYX"],
              },
              {
                key: "semi-bold",
                title: "Semi bold",
                rows: ["ABCDEFGHIJK", "LMNOPQRSTU", "VWXYX"],
              },
              {
                key: "bold",
                title: "Bold",
                rows: ["ABCDEFGHIJK", "LMNOPQRSTU", "VWXYX"],
              },
            ],
          },
        ],
      },
      {
        id: 5,
        key: "iconography",
        title: "Baseline/Regular",
        iconsLight: [
          `${imagesPath}soukup-partners/iconography-1.svg`,
          `${imagesPath}soukup-partners/iconography-2.svg`,
          `${imagesPath}soukup-partners/iconography-3.svg`,
          `${imagesPath}soukup-partners/iconography-4.svg`,
          `${imagesPath}soukup-partners/iconography-5.svg`,
          `${imagesPath}soukup-partners/iconography-6.svg`,
          `${imagesPath}soukup-partners/iconography-7.svg`,
          `${imagesPath}soukup-partners/iconography-8.svg`,
          `${imagesPath}soukup-partners/iconography-9.svg`,
        ],
        iconsDark: [
          `${imagesPath}soukup-partners/iconography-dark-1.svg`,
          `${imagesPath}soukup-partners/iconography-dark-2.svg`,
          `${imagesPath}soukup-partners/iconography-dark-3.svg`,
          `${imagesPath}soukup-partners/iconography-dark-4.svg`,
          `${imagesPath}soukup-partners/iconography-dark-5.svg`,
          `${imagesPath}soukup-partners/iconography-dark-6.svg`,
          `${imagesPath}soukup-partners/iconography-dark-7.svg`,
          `${imagesPath}soukup-partners/iconography-dark-8.svg`,
          `${imagesPath}soukup-partners/iconography-dark-9.svg`,
        ],
      },
      {
        id: 6,
        key: "presentation",
        title: "Desktop/Mobile",
        notebookImg: `${imagesPath}soukup-partners/presentation-notebook.png`,
        mobileImg1: `${imagesPath}soukup-partners/presentation-mobile-1.png`,
        mobileImg2: `${imagesPath}soukup-partners/presentation-mobile-2.png`,
        buttonUrl: "https://legalsoukup.cz",
      },
    ],
  },
];
