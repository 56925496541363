import React, { useState, useContext, useEffect, useRef } from "react";

// context
import AppContext from "../../../Store/AppContext";

function CaseStudyComponent({ project, isLandscape }) {
  const app = useContext(AppContext);
  const { imagesPath } = app.state;
  const [theme, setTheme] = useState("light");

  function renderSection(section) {
    let sectionContent = <></>;
    switch (section.key) {
      case "intro":
        sectionContent = (
          <div
            className="case-study-section case-study-intro"
            style={{
              backgroundImage: `url("${
                theme === "light" ? section.imgBck.light : section.imgBck.dark
              }")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <img src={section.img} alt="intro img" />
          </div>
        );
        break;
      case "about":
        sectionContent = (
          <div className="case-study-section case-study-about case-study-container">
            <div className="case-study-about-top">
              <h4>Introduction</h4>
              <h3>About This project</h3>
              <p>{section.description}</p>
            </div>
            <div className="case-study-about-overview">
              <div className="case-study-about-overview-left">
                <h4>Get started</h4>
                <h3>Overview</h3>
                <p>{section.overviewText}</p>
                <small>{section.overviewAuthor}</small>
                <span>Platform</span>
                <div className="platforms">
                  <div className="platforms-item platforms-item-mobile">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M20.6667 1.33331H10C8.16 1.33331 6.66666 2.82665 6.66666 4.66665V27.3333C6.66666 29.1733 8.16 30.6666 10 30.6666H20.6667C22.5067 30.6666 24 29.1733 24 27.3333V4.66665C24 2.82665 22.5067 1.33331 20.6667 1.33331ZM15.3333 29.3333C14.2267 29.3333 13.3333 28.44 13.3333 27.3333C13.3333 26.2266 14.2267 25.3333 15.3333 25.3333C16.44 25.3333 17.3333 26.2266 17.3333 27.3333C17.3333 28.44 16.44 29.3333 15.3333 29.3333ZM21.3333 24H9.33333V5.33331H21.3333V24Z"
                        fill="white"
                      />
                    </svg>
                    <p>Mobile</p>
                  </div>
                  <div className="platforms-item platforms-item-desktop">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M1.3626 0.00543023C0.948677 0.144577 0.591673 0.415386 0.346119 0.776496C0.100564 1.13761 -0.0200425 1.56916 0.00271968 2.00526V22.0036C0.00271968 22.5339 0.213416 23.0426 0.588456 23.4177C0.963497 23.7927 1.47216 24.0034 2.00255 24.0034H12.0017V28.003H8.00204C5.80223 28.003 4.00238 29.8029 4.00238 32.0027H28.0003C28.0003 29.8029 26.2005 28.003 24.0007 28.003H20.001V24.0034H30.0002C30.5306 24.0034 31.0392 23.7927 31.4143 23.4177C31.7893 23.0426 32 22.5339 32 22.0036V2.00526C32 1.47487 31.7893 0.966208 31.4143 0.591167C31.0392 0.216126 30.5306 0.00543023 30.0002 0.00543023L2.00255 0.00543023C1.8828 -0.00539081 1.76233 -0.00539081 1.64258 0.00543023C1.56266 0.000626313 1.48252 0.000626313 1.4026 0.00543023L1.3626 0.00543023ZM4.00238 4.00509H28.0003V20.0037H4.00238V4.00509Z"
                        fill="white"
                      />
                    </svg>
                    <p>Desktop</p>
                  </div>
                </div>
              </div>
              <div className="case-study-about-overview-right">
                <img
                  src={`${
                    theme === "light"
                      ? section.overviewImg.light
                      : section.overviewImg.dark
                  }`}
                  alt="img"
                />
              </div>
            </div>
          </div>
        );
        break;
      case "design":
        sectionContent = (
          <div className="case-study-section case-study-design case-study-container">
            <div className="case-study-heading">
              <img src={`${imagesPath}soukup-partners/heading-icon.png`} />
              <h3>UI Design</h3>
            </div>
            <h2>Style Guide</h2>
            <div className="case-study-design-colors">
              {section.colors &&
                section.colors.length > 0 &&
                section.colors.map((color, index) => (
                  <div className="case-study-design-colors-item" key={index}>
                    {theme === "light" ? (
                      <>
                        <img src={color.icon} alt="icon" />
                      </>
                    ) : (
                      <>
                        <img src={color.iconDark} alt="icon" />
                      </>
                    )}

                    <p>{color.text}</p>
                  </div>
                ))}
            </div>
          </div>
        );
        break;
      case "typography":
        sectionContent = (
          <div className="case-study-section case-study-typography case-study-container">
            <div className="case-study-heading">
              <img src={`${imagesPath}soukup-partners/heading-icon.png`} />
              <h3>Typography</h3>
            </div>
            {section.families &&
              section.families.length > 0 &&
              section.families.map((family, index) => (
                <div
                  className={`case-study-typography-section case-study-typography-${family.key}`}
                  key={index}
                >
                  <h2>{family.title}</h2>
                  <div className="types">
                    {family.types &&
                      family.types.length > 0 &&
                      family.types.map((type, index) => (
                        <div
                          className={`types-item types-item-${type.key}`}
                          key={index}
                        >
                          <h5>{type.title}</h5>
                          <div>
                            {type.rows.map((item, index) => (
                              <p key={index}>{item}</p>
                            ))}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
          </div>
        );
        break;
      case "iconography":
        sectionContent = (
          <div className="case-study-section case-study-iconography case-study-container">
            <div className="case-study-heading">
              <img src={`${imagesPath}soukup-partners/heading-icon.png`} />
              <h3>Iconography</h3>
            </div>
            <h2>{section.title}</h2>
            <div className="case-study-iconography-icons">
              {theme === "light" ? (
                <>
                  {section.iconsLight &&
                    section.iconsLight.length > 0 &&
                    section.iconsLight.map((icon, index) => (
                      <img src={icon} alt="icon" key={index} />
                    ))}
                </>
              ) : (
                <>
                  {section.iconsDark &&
                    section.iconsDark.length > 0 &&
                    section.iconsDark.map((icon, index) => (
                      <img src={icon} alt="icon" key={index} />
                    ))}
                </>
              )}
            </div>
          </div>
        );
        break;
      case "presentation":
        sectionContent = (
          <div className="case-study-section case-study-presentation">
            <div className="case-study-container">
              <div className="case-study-heading">
                <img src={`${imagesPath}soukup-partners/heading-icon.png`} />
                <h3>Website Presentation</h3>
              </div>
              <h2>{section.title}</h2>
            </div>
            <div className="case-study-presentation-content">
              <img src={section.notebookImg} alt="presentation img" />
              <div className="bottom">
                <div className="bottom-left">
                  <a href={section.buttonUrl} target="_blank">
                    Visit website
                  </a>
                </div>
                <div className="bottom-right">
                  <img src={section.mobileImg1} alt="presentation mobileimg" />
                  <img src={section.mobileImg2} alt="presentation mobile img" />
                </div>
              </div>
            </div>
          </div>
        );
        break;
      default:
        sectionContent = <></>;
        break;
    }
    return sectionContent;
  }

  return (
    <div
      className={`case-study case-study-${theme} case-study-${
        project.key || ""
      }`}
    >
      <div
        className={`case-study-theme-switch ${
          theme === "dark" ? "case-study-theme-switch-checked" : ""
        }`}
        onClick={() =>
          setTheme((state) => {
            if (state === "light") {
              return "dark";
            } else {
              return "light";
            }
          })
        }
      >
        <div className="case-study-theme-switch-inner">
          <div className="icon icon-left">
            <img src={`${imagesPath}/soukup-partners/day-icon.svg`} />
          </div>
          <div className="btn"></div>
          <div className="icon icon-right">
            <img src={`${imagesPath}/soukup-partners/night-icon.svg`} />
          </div>
        </div>
      </div>
      {project &&
        project.sections &&
        project.sections.length > 0 &&
        project.sections.map((section) => (
          <React.Fragment key={section.id}>
            {renderSection(section)}
          </React.Fragment>
        ))}

      <div className="case-study-footer">
        <h4>Thank you for your time</h4>
      </div>
    </div>
  );
}

export default CaseStudyComponent;
