import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// pages
import PortfolioPageComponent from "./Layout/Components/PortfolioPageComponent";
import NotFoundPageComponent from "./Layout/Components/NotFoundPageComponent";
import LoginPageComponent from "./Layout/Components/LoginPageComponent";
import ProtectedRoutes from "./Layout/Components/ProtectedRoutes";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* AUTH */}
          {/*
          <Route path="/login" element={<LoginPageComponent />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/" element={<PortfolioPageComponent />} />
            <Route
              exact
              path="/:project"
              element={<PortfolioPageComponent />}
            />
            <Route path="/*" element={<NotFoundPageComponent />} />
          </Route> */}

          {/* NO AUTH */}

          <Route path="/" element={<PortfolioPageComponent />} />
          <Route exact path="/:project" element={<PortfolioPageComponent />} />
          <Route path="/*" element={<NotFoundPageComponent />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
